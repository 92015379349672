import { ActionsUnion, createAction } from '@housinganywhere/safe-redux';
import { Location } from 'react-router-dom';

import { Language } from '@ha/intl';
import { reportError } from 'ha/helpers/bugReporter/reportError';
import { replaceLocation } from 'ha/modules/URLLocationHandler';
import { Thunk } from 'ha/myredux/types';
import {
  getIsAuthenticated,
  getImpersonatingAdmin,
} from 'ha/modules/AuthLogic/selectors';
import { GlobalState } from 'ha/types/store';

import { track } from '@hbf/analytics';
import { getLocalisedLocation } from '../selectors';

export enum ActionTypes {
  START = 'LANGUAGE_SWITCHER/CHANGE_LANGUAGE_START',
}

export const Actions = {
  start: (nextLanguage: string) => {
    return createAction(ActionTypes.START, nextLanguage);
  },
};

export type ActionsType = ActionsUnion<typeof Actions>;

const changeLanguage =
  (nextLanguageCode: Language, currentLocation: Location): Thunk<GlobalState> =>
  (dispatch, getState, { apiV2 }) => {
    const isAuthenticated = getIsAuthenticated(getState());
    const isImpersonated = Boolean(getImpersonatingAdmin(getState()));

    track('Set different language', {
      language: nextLanguageCode,
    });
    dispatch(Actions.start(nextLanguageCode));

    const promise =
      isAuthenticated && !isImpersonated
        ? apiV2
            .updateUser('me', {
              lang: nextLanguageCode,
            })
            .catch((error: Error) => {
              reportError('Change Language Error', { metaData: { error } });
            })
        : Promise.resolve();

    return promise.then(() => {
      const state = getState();

      dispatch(
        replaceLocation(
          getLocalisedLocation(state, nextLanguageCode, currentLocation),
        ),
      );
    });
  };

export { changeLanguage };
