import React from 'react';
import { useNavigation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import LinearProgress from '@mui/material/LinearProgress';

const useStyles = makeStyles()(() => ({
  root: {
    position: 'fixed',
    width: '100%',
    // 1300 is the z-index for the Google Map on search page
    // and we want this progress bar to be above that.
    zIndex: 1301,
  },
}));

export const ProgressBar: React.FC = () => {
  const isLoading = useNavigation().state === 'loading';
  const { classes } = useStyles();

  if (!isLoading) {
    return null;
  }

  return (
    <div className={classes.root} data-test-locator="PageProgressBar">
      <LinearProgress />
    </div>
  );
};
