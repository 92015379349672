import React from 'react';

import { useDispatch } from 'react-redux';

import { Helmet } from 'react-helmet-async';

import { AUTH_SIGNIN_WITH_GOOGLE_ONE_TAP_URL } from '@ha/auth/common/routes';

import { useConfig } from 'ha/helpers/config';
import { useIntl } from 'ha/i18n';
import { useTrackEvent } from 'ha/modules/Analytics/helpers/TrackEvent';

import { sendAlert } from '../Alertbar/actions';
import { AlertType } from '../Alertbar/types';

const GoogleOneTapCallback = 'GoogleOneTapCallback';
const GOOGLE_ONE_TAP_EVENT = 'Clicked on Google One Tap';
const GOOGLE_ONE_TAP_FAILURE_EVENT = 'Failed Google One Tap';

interface GoogleOneTapResponse {
  clientId: string;
  credential: string;
  select_by: string;
}

// TODO: refactor
// eslint-disable-next-line react/function-component-definition
export function GoogleOneTap() {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();
  const { google } = useConfig();
  const { T } = useIntl();

  React.useEffect(() => {
    const callback = async (response: GoogleOneTapResponse) => {
      try {
        trackEvent(GOOGLE_ONE_TAP_EVENT);

        const url = new URL(window.location.href);
        url.pathname = AUTH_SIGNIN_WITH_GOOGLE_ONE_TAP_URL;

        // The response sets the required cookies to work with the IdP
        const request = await fetch(url.href, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ credential: response.credential }),
        });
        if (!request.ok) {
          throw new Error(request.status.toString());
        }

        window.location.reload();
      } catch (error) {
        trackEvent(GOOGLE_ONE_TAP_FAILURE_EVENT);

        // Not Logged in
        const errorMessage = T('We could not connect to your Google account');

        dispatch(
          sendAlert({
            text: errorMessage,
            kind: AlertType.Danger,
            dismissAfter: 10000,
          }),
        );
      }
    };

    window[GoogleOneTapCallback] = callback;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <script src="https://accounts.google.com/gsi/client" async defer />
      </Helmet>
      <div
        id="g_id_onload"
        data-test-locator="GoogleOneTap"
        data-client_id={google.client_id}
        data-callback={GoogleOneTapCallback}
      />
    </React.Fragment>
  );
}
