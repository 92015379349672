import React from 'react';

import { Link } from '@mui/material';

import ResizeObserver from 'resize-observer-polyfill';
import { makeStyles } from 'tss-react/mui';

import { usePageComponentsInfo } from 'ha/modules/Page/contexts/PageComponentsInfoContext';

const useStyles = makeStyles()(theme => ({
  container: {
    position: 'sticky',
    width: 'max-content',
    minWidth: '100vw',
    bottom: 0,
    zIndex: 1020,
    borderTop: `1px solid ${theme.palette.mono.main}`,
    background: theme.palette.neutral[100],
    paddingInline: theme.spacing(3),
    paddingBlock: theme.spacing(2, 3),
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    minWidth: 'min-content',
    gap: theme.spacing(1),
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  itemIcon: {
    display: 'flex',
    padding: theme.spacing(2),
  },
}));

const NavigationBottomBarItemIcon = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const { classes } = useStyles();

  return <div className={classes.itemIcon}>{children}</div>;
};

interface NavigationBottomBarItemProps {
  slots: { icon: React.ReactNode };
}

const NavigationBottomBarItem = ({
  children,
  slots,
}: React.PropsWithChildren<NavigationBottomBarItemProps>) => {
  const { classes } = useStyles();

  return (
    <div className={classes.item}>
      {slots.icon}
      {children}
    </div>
  );
};

const NavigationBottomBarItemLink = React.forwardRef(
  (
    props: NonNullable<unknown>,
    ref: React.ForwardedRef<React.ComponentRef<typeof Link>>,
  ) => {
    return <Link ref={ref} {...props} color="inherit" underline="none" />;
  },
) as typeof Link;

NavigationBottomBarItem.Icon = NavigationBottomBarItemIcon;
NavigationBottomBarItem.Link = NavigationBottomBarItemLink;

const NavigationBottomBarContent = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const { classes } = useStyles();

  return <div className={classes.content}>{children}</div>;
};

const NavigationBottomBarContainer = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const { classes } = useStyles();
  const { setBottomNavbarHeight } = usePageComponentsInfo();
  const containerEl = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const container = containerEl.current;
    if (!container) return undefined;

    const updateBottomNavbarHeigh = () => {
      setBottomNavbarHeight(container.offsetHeight);
    };

    const resizeObserver = new ResizeObserver(updateBottomNavbarHeigh);

    resizeObserver.observe(container);

    return () => resizeObserver.disconnect();
  }, [setBottomNavbarHeight]);

  return (
    <div
      className={classes.container}
      ref={containerEl}
      data-test-locator="Navigation-Bar-Bottom"
    >
      {children}
    </div>
  );
};

export const NavigationBottomBar = {
  Container: NavigationBottomBarContainer,
  Content: NavigationBottomBarContent,
  Item: NavigationBottomBarItem,
};
