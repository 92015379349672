import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { FetchStatus } from '@ha/api/v2/errors/FetchStatus';
import {
  GetUserKYCsResponse,
  KYCEntity,
  KycInformationWithFetchStatus,
  KycStates,
  PostUserKYCPayload,
} from '@ha/api/v2/types/Kyc';

import { useIntl } from 'ha/i18n';
import { useTrackEvent } from 'ha/modules/Analytics/helpers/TrackEvent';
import {
  getUserId,
  getIsAdvertiser,
  getIsIcelandicLandlord,
} from 'ha/modules/AuthLogic/selectors';
import { useServices } from 'ha/services';

import { send as sendAlert } from 'ha/modules/Alertbar/actions';

import { useShowAlert, AlertType } from '../Alertbar/helpers/ShowAlert';
import { useFeatureFlags } from '../FeatureFlags';

import { determineKYCState } from './utils/determineKYCState';

export interface UseKycInfo {
  kycInfo: KycInformationWithFetchStatus;
  userKYCDetails: GetUserKYCsResponse;
  showKYCBanner: boolean;
  kycState: KycStates;
  fetchKycCountriesStatus: FetchStatus;
  formSubmitStatus: FetchStatus;
  kycCountries: string[];
  actions: {
    loadKycCountries: () => void;
    loadKycNextSteps: (uuid: KYCEntity['uuid']) => void;
    postKycEntity: (data: PostUserKYCPayload) => void;
  };
}

export const KycInformation = React.createContext<UseKycInfo>({} as UseKycInfo);

KycInformation.displayName = 'KycInformation';

export const useKycInfo = () => {
  const value = React.useContext(KycInformation);
  if (!value) {
    throw Error(
      'useKycInfo used outside KycInfo wrap component in KycProvider',
    );
  }
  return value;
};

export const KycProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { apiV2 } = useServices();
  const showAlert = useShowAlert();
  const trackEvent = useTrackEvent();
  const { T } = useIntl();
  const dispatch = useDispatch();
  const { showKYCBanner } = useFeatureFlags();
  const isAdvertiser = useSelector(getIsAdvertiser);
  const userId = useSelector(getUserId);
  const isIcelandicLandlord = useSelector(getIsIcelandicLandlord);

  const [kycInfo, setKycInfo] = React.useState<KycInformationWithFetchStatus>({
    fetchStatus: 'loadingFirstTime',
    kycDone: false,
    kycStarted: false,
    requirements: {
      hasExpiringRequirements: false,
      requirementsDeadlineAt: '',
    },
  });

  const [userKYCDetails, setUserKYCDetails] =
    React.useState<GetUserKYCsResponse>({
      count: 0,
      entities: [],
    });

  const [fetchKycCountriesStatus, setFetchKycCountriesStatus] =
    React.useState<FetchStatus>('loadingFirstTime');

  const [kycCountries, setKycCountries] = React.useState<
    UseKycInfo['kycCountries']
  >([]);

  const [formSubmitStatus, setFormSubmitStatus] =
    React.useState<FetchStatus>('loadingFirstTime');

  const [kycState, setKycState] = React.useState<KycStates>(KycStates.UNKNOWN);

  React.useEffect(() => {
    // fetch KYC details only when users can be KYCed
    if (!isIcelandicLandlord) {
      Promise.all([apiV2.getKycInfo('me'), apiV2.getUserKYCs('me')])
        .then(([kycInfoResponse, userKycResponse]) => {
          setKycInfo({
            kycDone: kycInfoResponse.data.kycDone,
            kycStarted: kycInfoResponse.data.kycStarted,
            fetchStatus: 'success',
            requirements: kycInfoResponse.data.requirements,
          });
          setUserKYCDetails(userKycResponse.data);

          const kycStateValue = determineKYCState(
            kycInfoResponse.data,
            userKycResponse.data.entities.length
              ? userKycResponse.data.entities[0].kycStatus
              : 'unknown',
          );
          setKycState(kycStateValue);
        })
        .catch(() => {
          setKycInfo({
            kycDone: false,
            kycStarted: false,
            fetchStatus: 'failure',
            requirements: {
              hasExpiringRequirements: false,
              requirementsDeadlineAt: '',
            },
          });
          showAlert({
            kind: AlertType.Danger,
            text: T('payments.payout_methods.error.identity_verification'),
          });
        });
    }
  }, [apiV2, showAlert, T, isIcelandicLandlord]);

  const loadKycCountries = React.useCallback<
    UseKycInfo['actions']['loadKycCountries']
  >(() => {
    setFetchKycCountriesStatus('loading');

    apiV2
      .getKycCountries('me', { asCountryOfResidence: true }) // asCountryOfResidence: true adds "IS" to the response
      .then(({ data }) => {
        setKycCountries(data.acceptedCountryCodes);
        setFetchKycCountriesStatus('success');
      })
      .catch(() => setFetchKycCountriesStatus('failure'));
  }, [apiV2]);

  const loadKycNextSteps = React.useCallback<
    UseKycInfo['actions']['loadKycNextSteps']
  >(
    async (uuid: string) => {
      await apiV2.getUserKycByUuid('me', uuid).then(({ data }) => {
        if (data.nextSteps && data.nextSteps.length) {
          window.location.replace(data.nextSteps[0].redirectUrl);
        }
      });
    },
    [apiV2],
  );

  const postKycEntity = React.useCallback<
    UseKycInfo['actions']['postKycEntity']
  >(
    kycData => {
      setFormSubmitStatus('loading');

      apiV2
        .postUserKYC('me', kycData)
        .then(({ data }) => {
          if (!data.nextSteps) {
            return Promise.reject();
          }

          trackEvent('begin verification redirected', { userId });
          setTimeout(() => {
            if (data.nextSteps && data.nextSteps.length) {
              window.location.replace(data.nextSteps[0].redirectUrl);
            }

            setFormSubmitStatus('success');
          }, 1000);

          return Promise.resolve();
        })
        .catch(error => {
          if (error?.status === 409) {
            dispatch(
              sendAlert({
                text: T(
                  'payments.verify_identity.snackbar_verification_already_in_progress',
                ),
                kind: AlertType.Danger,
                dismissAfter: 10000,
              }),
            );
          }
          setFormSubmitStatus('failure');
        });
    },
    [apiV2, dispatch, trackEvent, userId, T],
  );

  const displayKycBanner =
    isAdvertiser &&
    kycState !== KycStates.UNKNOWN &&
    kycState !== KycStates.PENDING &&
    kycState !== KycStates.VERIFIED &&
    kycInfo.fetchStatus === 'success' &&
    showKYCBanner === 'on' &&
    !isIcelandicLandlord;

  const values: UseKycInfo = React.useMemo(
    () => ({
      kycInfo,
      userKYCDetails,
      showKYCBanner: displayKycBanner,
      kycState,
      kycCountries,
      fetchKycCountriesStatus,
      formSubmitStatus,
      actions: {
        loadKycCountries,
        loadKycNextSteps,
        postKycEntity,
      },
    }),
    [
      kycInfo,
      userKYCDetails,
      displayKycBanner,
      kycState,
      kycCountries,
      fetchKycCountriesStatus,
      formSubmitStatus,
      loadKycCountries,
      loadKycNextSteps,
      postKycEntity,
    ],
  );

  return (
    <KycInformation.Provider value={values}>{children}</KycInformation.Provider>
  );
};
