import React from 'react';

import { HttpStatus } from 'ha/constants/HttpStatus';

const Context = React.createContext<{ status?: HttpStatus } | undefined>(
  undefined,
);

export const StatusProvider = Context.Provider;

export interface FilledContext {
  status: HttpStatus;
}

export const StatusCode: React.FC<FilledContext> = ({ status }) => {
  const value = React.useContext(Context);

  if (value) {
    value.status = status;
  }

  return null;
};
