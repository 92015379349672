import React from 'react';
import { connect, useDispatch } from 'react-redux';

import { send as showAlert } from '../../actions';
import { ShowAlert } from '../../types';

export { AlertType, AlertDismissAfter } from '../../types';

type ResultType = (alert: ShowAlert) => void;

export const useShowAlert = (): ResultType => {
  const dispatch = useDispatch();

  return React.useCallback(
    (...args) => dispatch(showAlert(...args)),
    [dispatch],
  );
};

export const withShowAlert = connect(null, {
  showAlert,
});
