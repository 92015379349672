import React from 'react';
import { createRequiredContext } from 'ha/core/RequiredContext';

interface PageLayoutContextInterface {
  hasStickyNav?: boolean;
  hideCTA?: boolean;
  hasSidebar?: boolean;
  searchBarElement?: React.ReactNode;
}

export const PageLayoutContext =
  createRequiredContext<PageLayoutContextInterface>('PageLayoutContext');

export const PageLayoutProvider: React.FC<
  React.PropsWithChildren<PageLayoutContextInterface>
> = ({ hasStickyNav, hasSidebar, searchBarElement, hideCTA, children }) => {
  const value = React.useMemo(
    () => ({ hasStickyNav, searchBarElement, hideCTA, hasSidebar }),
    [hasStickyNav, searchBarElement, hideCTA, hasSidebar],
  );

  return (
    <PageLayoutContext.Provider value={value}>
      {children}
    </PageLayoutContext.Provider>
  );
};
