import React from 'react';
import { X } from '@hbf/icons/brand-bold';
import { IconButton } from '@hbf/dsl/core';
import { makeStyles } from 'tss-react/mui';
import { Drawer } from '@mui/material';
import { NavigationLogo } from '../NavigationLogo/NavigationLogo';

interface NavigationDrawerProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles()(theme => ({
  drawerLayout: {
    width: `min(300px, 100vw)`,
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      width: `min(400px, 100vw)`,
    },
  },
  drawerHeader: {
    padding: `${theme.spacing(4)} ${theme.spacing(5)}`,
    borderBottom: `1px solid ${theme.palette.mono.main}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5),
    gap: theme.spacing(5),
  },
}));

const NavigationDrawerBlock = ({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) => {
  const { classes, cx } = useStyles();

  return <div className={cx(classes.block, className)}>{children}</div>;
};

export const NavigationDrawer = ({
  open,
  onClose,
  children,
}: React.PropsWithChildren<NavigationDrawerProps>) => {
  const { classes } = useStyles();

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <div className={classes.drawerLayout}>
        <div className={classes.drawerHeader}>
          <NavigationLogo size="full" />

          <IconButton onClick={onClose}>
            <X />
          </IconButton>
        </div>

        {children}
      </div>
    </Drawer>
  );
};

NavigationDrawer.Block = NavigationDrawerBlock;
