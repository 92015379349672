import React from 'react';
import { AuthLinkInfo } from '../types';

interface PageContextInterface {
  name: string;
  category?: string;
  authLinkInfo: AuthLinkInfo;
}

export const PageContext = React.createContext<PageContextInterface>({
  name: '',
  category: '',
  authLinkInfo: {},
});

interface ProviderProps {
  name: string;
  category?: string;
  authLinkInfo: AuthLinkInfo;
}

export const PageProvider: React.FC<React.PropsWithChildren<ProviderProps>> = ({
  name,
  category,
  authLinkInfo,
  children,
}) => {
  const value = React.useMemo(
    () => ({ name, category, authLinkInfo }),
    [category, name, authLinkInfo],
  );

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};

export const usePage = () => React.useContext(PageContext);

export const PageConsumer = PageContext.Consumer;
